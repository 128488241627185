import * as React from 'react'
import { Flex } from 'grid-styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Hero from '../../components/Hero'
import Button from '../../components/Button'
import BackgroundSection from '../../components/BackgroundSection'
import MainLayout from '../../components/Layout'
import { AboutContact } from '..'
import {
  BeforeAfter,
  StyledTabs,
  StyledTabList,
  StyledTab,
  Content,
} from '../../components/procedures'
import { TabPanel } from 'react-tabs'

const content = {
  backgroundTitle: 'Other Body Surgery Options',
  subtitle: `Trusted, Experienced & Ethical. Boutique plastic surgery on Sydney’s North Shore`,
  title: 'Abdominoplasty (Tummy Tuck)',
  otherOptions: [
    // {
    //   text: 'Liposuction',
    //   to: '/procedures/liposuction',
    // },
    {
      text: 'Abdominoplasty (Tummy Tuck)',
      to: '/procedures/tummy-tuck',
    },
    {
      text: 'Skin Cancer Surgery',
      to: '/procedures/skin-cancer-surgery',
    },
    {
      text: 'Scar Revision',
      to: '/procedures/scar-revision',
    },
    {
      text: 'Mole Removal',
      to: '/procedures/mole-removal',
    },
  ],
}

export default ({ data }) => (
  <MainLayout header="light">
    <Helmet>
      <title>Abdominoplasty (Tummy Tuck) in Sydney - Dr. Graham Sellars</title>
      <meta
        name="description"
        content="Easily remove excess fats from the abdominal area by undergoing our tummy tuck procedure."
      />
    </Helmet>

    <Hero bgImage={data.womanWaterfall.childImageSharp} height="600px">
      <h1>{content.title}</h1>
      <p>{content.subtitle}</p>
      <Flex>
        <Button color="#fff" small={true} outline={true}>
          Learn more
        </Button>
      </Flex>
    </Hero>

    <Content>
      <h2>Abdominoplasty (Tummy Tuck)</h2>
      <h4>
        An abdominoplasty, also known as a tummy tuck, is a surgical procedure
        performed to remove excess fat and sagging skin around the lower
        abdomen.
      </h4>

      <p>
        Generally, the loose abdominal muscles under the skin are also tightened
        to create a significantly smoother and firmer profile.
      </p>
      <p>
        Abdominoplasty (Tummy tucks) improve the shape and tone of the tummy region by removing
        excess fat and skin, and restoring weakened or separated muscles. It is
        a very common plastic surgery procedure and has a very high level of
        patient satisfaction. Abdominoplasties are highly individualized,
        considering the unique situation of every patient who chooses to undergo
        the procedure.
      </p>

      <p>
        An abdominoplasty is not and should not be seen as a substitute for
        weight loss or exercise routine. This is a point Dr. Graham Sellars
        wants to reinforce to all patients. The procedure also cannot correct
        stretch marks and other similar problems.
      </p>

      <p>
        While the procedure achieves great results, it is not for everyone. The
        best candidates for abdominoplasty are, first and foremost, patients who
        are in good health. It is ideal for men and women who were once obese,
        but still have loose skin or excess deposits of fat around their lower
        abdomen. It is also recommended for women who have had several
        pregnancies and would like to tighten muscles and reduce skin around
        their abdominal area.
      </p>
      <Link to="/contact/">
        <Button inverted={true}>Contact us today!</Button>
      </Link>
    </Content>

    <BackgroundSection
      image={data.otherBreast.childImageSharp}
      title={content.backgroundTitle}
      height="500px"
      darkness="0.4"
    >
      <p style={{ fontSize: 18, margin: '-1rem 0 2rem' }}>
        Browse Dr. Graham Sellars' full range of surgical and non-surgical
        procedures.
      </p>
      {content.otherOptions.map(({ to, text }) => (
        <Flex mr={2} mb={3} style={{ float: 'left' }}>
          <Link to={to}>
            <Button small={true} color="#fff" outline={true}>
              {text}
            </Button>
          </Link>
        </Flex>
      ))}
    </BackgroundSection>

    <StyledTabs style={{ marginTop: 50 }}>
      <h2>Information about {content.title}</h2>
      <StyledTabList>
        <StyledTab>Pre operative care</StyledTab>
        <StyledTab>Surgery</StyledTab>
        <StyledTab>Post operative care</StyledTab>
        <StyledTab>Risks of Surgery</StyledTab>
      </StyledTabList>

      <TabPanel>
        <h4>
          Dr Sellars will have at least 2 consultations with you to determine
          your concerns and objectives, and to ensure that you have realistic
          expectations of what can be achieved.
        </h4>
        <p>
          He will show you before and after photos of people that have had this
          surgery and give you reading material to take home.
        </p>

        <p>
          Many people after pregnancy and weight loss, find they cannot lose the
          excess fat and loose skin even through diet and exercise. An
          abdominoplasty can be very successful in people who have lost a lot of
          weight but still have a remaining tummy bulge.
        </p>

        <p>
          Abdominoplasty is not usually recommended to people purely as a weight
          loss solution, and surgeons may require the patient to try to lose
          more body weight before considering an operation.
        </p>
        <p>Length of stay in hospital is usually 2-4 days.</p>
      </TabPanel>
      <TabPanel>
        <h4>
          Before commencing the surgery, a few lines will be drawn on the
          abdomen. The surgeon then makes a long incision usually just above the
          pubic region from hip to hip.
        </h4>
        <p>
          The length of the incision will vary depending on the laxity of the
          skin. The skin and underlining fat are then dissected away from the
          abdominal muscles and retracted backwards. The abdominal muscles are
          then surgically tightened by suturing them back into the correct
          position, which then gives the tummy a more toned and taut shape.
        </p>

        <p>
          The skin is then pulled downwards and another incision is made around
          the navel, which will be resutured into a higher position when the
          excess sagging skin is removed.
        </p>

        <p>
          Once a new hole has been created for the navel, the excess fat and
          skin are excised and the wound is closed.
        </p>

        <p>
          Usually 1-2 drains are placed into the wound under the suture line, to
          drain any excess fluid that may accumulate immediately after the
          surgery.
        </p>

        <p>These are removed between 2-4 days.</p>
      </TabPanel>
      <TabPanel>
        <h4>
          Dr Sellars uses sutures that are absorbable, and therefore do not need
          to be removed postoperatively.
        </h4>
        <p>
          A large dressing will be applied to the area, and is changed
          periodically.
        </p>
        <p>
          You will experience mild to moderate discomfort, and Dr Sellars will
          prescribe medication for you during the hospital stay and for the
          following weeks if required.
        </p>

        <p>
          It is preferable to have someone at home with you for a few days after
          discharge from hospital as you will not want to be doing any lifting
          or excessive movements for a little while.
        </p>

        <p>You should avoid strenuous exercise for 4-6 weeks.</p>
      </TabPanel>
      <TabPanel>
        <h4>Possible risks of any surgery include:</h4>
        <ul>
          <li>Wound infection</li>
          <li>Pain and discomfort</li>
          <li>Nausea</li>
          <li>Haematoma (an accumulation of blood around the surgical site that may require drainage)</li>
          <li>Heavy bleeding from the incisions</li>
          <li>Keloid or hypertrophic scars</li>
          <li>Slow healing</li>
          <li>Separation of the wound edges</li>
          <li>Allergies to anaesthetic agents, antiseptics, suture material or dressings</li>
          <li>Deep vein thrombosis DVT (blood clot in the leg)</li>
        </ul>

        <h4>Specific abdominoplasty risks:</h4>
        <ul>
          <li>Haematoma or seroma (collection of blood or fluid) that may require draining</li>
          <li>Swelling may take several months to resolve in some cases</li>
          <li>Scar may appear to worsen during the first six months of healing</li>
          <li>Numbness around the scars in the lower abdominal area and sometimes in the upper thighs</li>
          <li>Sometimes scar revision is required</li>
        </ul>
      </TabPanel>
    </StyledTabs>

    <BeforeAfter>
      <h2>Before &amp; After</h2>
      <div>
        {data.allFile.edges.map(
          (image) =>
            image.node.childImageSharp && (
              <Img resolutions={image.node.childImageSharp.resolutions} />
            )
        )}
      </div>
      <Flex justifyContent="center">
        <Link to="/gallery">
          <Button inverted={true}>See the full gallery</Button>
        </Link>
      </Flex>
    </BeforeAfter>

    <AboutContact data={data} />
  </MainLayout>
)
export const query = graphql`
  query TummyTuckQuery {
    allFile(filter: { relativeDirectory: { eq: "tummy-tuck" } }) {
      edges {
        node {
          childImageSharp {
            resolutions(width: 500, height: 300, toFormat: JPG) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
    womanWaterfall: file(relativePath: { eq: "background-generic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    otherBreast: file(relativePath: { eq: "water-body-woman.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asaps: file(relativePath: { eq: "ASAPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    asps: file(relativePath: { eq: "ASPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "dr-graham-sellars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fracs: file(relativePath: { eq: "FRACS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
